import {Component, Input, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import {high_colors} from "../general-chart/general-chart.component";

@Component({
  selector: 'app-portfolio-return-bar-chart',
  templateUrl: './portfolio-return-bar-chart.component.html',
  styleUrls: ['./portfolio-return-bar-chart.component.scss']
})
export class PortfolioReturnBarChartComponent implements OnInit {
  @Input() rawData: any;
  @Input() title: any = '';
  @Input() subHeader: any = {};
  @Input() isPortfolioPerformance: boolean = false;

  loaded = false;
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = 'chart';
  chartOptions: Highcharts.Options = {};
  chartType: string = 'volatility';
  series: any[] = [];
  name: string = '';
  index: any = [];

  ngOnInit(): void {
    if (this.isPortfolioPerformance) {
      this.rawData[0].data.sort((a, b) => {
        return b.value - a.value;
      })

      this.rawData[1].data.sort((a, b) => {
        return b.value - a.value;
      })
    }
    this.setRiskData();
  }

  ngOnChanges(): void {

    this.changeData();
  }

  changeData() {
    if (this.chartType === 'volatility') {
      this.setRiskData();
    } else {
      this.setRiskData();
    }
  }

  private setRiskData() {
    if (this.isPortfolioPerformance) {
      this.series = [{
        type: 'bar',
        name: 'Volatility',
        data: this.rawData[1].data.map(item => item.value)
      }];
      this.index = this.rawData[1];
    } else {
      this.rawData[0].data.sort((a, b) => {
        return b.risk - a.risk;
      })
      this.series = [{
        type: 'bar',
        name: 'Risk',
        data: this.rawData[0].data.map(item => item.risk)
      }];
      this.chartType = 'risk';
      this.index = this.rawData[0];
    }
    this.initChart();
  }

  private setReturnData() {
    if (this.isPortfolioPerformance) {
      this.series = [{
        type: 'bar',
        name: 'Return',
        data: this.rawData[0].data.map(item => item.value)
      }];
      this.index = this.rawData[0];
    } else {
      this.rawData[0].data.sort((a, b) => {
        return b.return - a.return;
      })
      this.series = [{
        type: 'bar',
        name: 'Return',
        data: this.rawData[0].data.map(item => item.return)
      }];
      this.index = this.rawData[0];
    }
    this.initChart();
  }

  initChart() {
    this.loaded = false;

    // if (this.isPortfolioPerformance) {
    //   this.index = this.rawData[0];
    //   if(this.chartType == 'risk')
    //     this.index = this.rawData[1];
    // }else{
    //   this.index = this.rawData[0]
    // }
    
    this.chartOptions = {
      chart: {
        height: '500px',
        type: 'area'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        valueSuffix: '%',
        shared: true
      },
      plotOptions: {
        bar: {
          colorByPoint: true,
          colors: [
            '#424CCD'
          ],
          negativeColor: '#9f2112'
        }
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories: this.index.data.map(item => item.company),
        title: {
          text: null
        },
        gridLineColor: '#ffff',
      },
      yAxis: {
        labels: {
          format: '{text}%'
        },
        gridLineColor: '#ffff',
        title: {
          text: this.chartType.charAt(0).toUpperCase() + this.chartType.slice(1),

        },
        
      },
      series: this.series
    };
    setTimeout(() => this.loaded = true, 10);
  }
}
