<form action="" class="position-relative">
  <div class="row">
    <div class="col-md-9">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Name of portfolio</mat-label>
        <input matInput placeholder="Name of portfolio" name="name" [(ngModel)]="portfolio.name" [required]="true">
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Description</mat-label>
        <textarea matInput placeholder="Description" name="description" [(ngModel)]="portfolio.description" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <app-filter-select label="Base Currency" [needAllOption]="false" [isFullWidth]="true" [list]="filterLists.main_currencies" [model]="portfolio.currency_id" [isRequired]="true"
                         (modelChanged)="portfolio.currency_id = $event;onHedgingChange()"></app-filter-select>
    </div>
    <div class="col-md-2">
      <app-cim-datepicker label="Start date" [minDate]="maxStartDate" [model]="portfolio.start_date" [isRequired]="true" [isFullWidth]="true" (modelChanged)="portfolio.start_date = $event; onStartDateSet()"></app-cim-datepicker>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline" class="w-100" [matMenuTriggerFor]="hedgingmenu" [ngClass]="{ 'menu-disabled' :!currencyCount }">
        <mat-label>FX Hedging <span *ngIf="selectedCurrencies.length && totalHedgingCount">({{totalHedgingCount}}/{{currencyCount}})</span></mat-label>
        <input matInput name="totalhedging" [(ngModel)]="totalHedging">
      </mat-form-field>

      <mat-menu #hedgingmenu="matMenu" [overlapTrigger]="false"  class="fx-hedging-menu" >
        <div  mat-menu-item class="pt-2" *ngFor="let c of selectedCurrencies" (click)="$event.preventDefault();$event.stopPropagation();" [hidden]="c.id == portfolio.currency_id">
          <div class="fx-hedging-item" >
            <span class="fx-hedging-currency">{{c.code}}</span>
            <mat-slider
              color="primary"
              [name]="'slider'+c.code"
              [max]="100"
              [min]="0"
              [step]="1"
              [thumbLabel]="true"
              [value]="c.hedging"
              [(ngModel)]="c.hedging"
              (ngModelChange)="onHedgingChange()"
            >
            </mat-slider>
          </div>
        </div>
      </mat-menu>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label >Rebalancing </mat-label>
        <mat-select
          name="weighting"
          placeholder="Rebalancing"
          panelClass="cim-select"
          [(ngModel)]="portfolio.rebalancing_type"
        >
          <mat-option value="no">No Rebalancing</mat-option>
          <mat-option value="monthly">Monthly</mat-option>
          <mat-option value="quarterly">Quarterly</mat-option>
          <mat-option value="yearly">Yearly</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2" [hidden]="portfolio.rebalancing_type == 'no'">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Cost of rebalancing (basis points)</mat-label>
        <input matInput placeholder="Cost of rebalancing (basis points)" type="number" name="rebalancing_cost" [(ngModel)]="portfolio.cost_of_rebalancing">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label >Weighting </mat-label>
        <mat-select
          name="weighting"
          placeholder="Weighting"
          panelClass="cim-select"
          [(value)]="portfolio.weighting_type"
          (valueChange)="onWeightTypeChange()"
        >
          <mat-option [value]="'manual'">Manual</mat-option>
          <mat-option [value]="'equal'">Equal Weighted</mat-option>
          <mat-option [value]="'market_cap'">Market Cap Weighted</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2">
       <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Risk Free Rate</mat-label>
                    <input matInput value="1.5" type="number" step="0.01">
                    <span matSuffix>%</span>
                  </mat-form-field>

    </div>
    <div class="col-md-2">
         <app-benchmark-selector [selectedBenchmark]="selectedBenchmark"
                                           ></app-benchmark-selector>
    </div>
    <div class="col-md-3" *ngIf="hasETF">
      <small class="text-muted">% of ETFs in portfolio:</small>
      <mat-slider
        class="w-100"
        color="primary"
        name="etfPercent"
        [max]="100"
        [min]="0"
        [step]="1"
        [thumbLabel]="true"
        [value]="portfolio.etfPercent"
        [(ngModel)]="portfolio.etfPercent"
        (ngModelChange)="onWeightTypeChange()"
      >
      </mat-slider>
    </div>
    <div class="col" *ngIf="portfolio.weighting_type == 'equal' && hasETF">
      <button mat-flat-button color="primary" (click)="setAllAssetEqual()">Set all equal</button>
    </div>
  </div>
</form>
